import { whenever } from '@vueuse/core'
import { createInjectionState } from '@lasso/shared/utils'
import { RefOrGetter } from '@lasso/shared/types'
import { Ref, computed, toValue } from 'vue'
import { UseFormAddonsContext, useApi } from '@lasso/shared/hooks'
import { useActivationApi } from '@lasso/api-activation/activation'
import { v4 as uuidv4 } from 'uuid'

import { isAdvancedManagementAvailable } from './utils'
import { CardFrequencyFormData, FrequencyCap, FrequencyFormInput } from './types'

export const [useProvideCardFrequency, useCardFrequency] = createInjectionState(({
  isCardEnabled,
  adGroupId,
  isDirectSold,
  useFieldModelsStartingWith,
  resetField,
  getId,
  useFieldArray,
}: {
  adGroupId: Readonly<Ref<number>>
  isCardEnabled: RefOrGetter<boolean>
  isDirectSold: boolean
} & UseFormAddonsContext<CardFrequencyFormData>) => {
  const { frequencyCaps } = useFieldModelsStartingWith('frequency', [
    'frequencyCaps',
  ])

  const { recencyValue, recencyTimeUnit } = useFieldModelsStartingWith('recency', [
    'recencyValue',
    'recencyTimeUnit',
  ])

  const setInitialFrequency = (frequencyCaps: FrequencyFormInput['frequencyCaps']) => {
    return resetField('frequency.frequencyCaps', { value: frequencyCaps })
  }

  const { loading, onData, error, request } = useApi(
    useActivationApi().getAdGroupFrequencies,
    () => {
      return toValue(isCardEnabled)
        ? [{ adGroupId: adGroupId.value, paging: { pageSize: 9999 } }]
        : null
    },
    { refetch: true },
  )

  const buildFrequencyCap = (): FrequencyCap => {
    return {
      id: 0,
      localId: uuidv4(),
      value: null,
      timeUnit: null,
      advancedManagement: true,
    }
  }

  onData(({ data }) => {
    const caps = data?.map((item): FrequencyCap => ({
      ...buildFrequencyCap(),
      id: item.id ?? 0,
      value: item.frequencyValue ?? null,
      timeUnit: item.frequencyValue ? (item.frequencyTimeUnit || null) : null,
      advancedManagement: item.advancedManagement || false,
    })) ?? []

    setInitialFrequency(caps.length > 0 ? caps : [buildFrequencyCap()])
  })

  const canAddFrequencyCap = computed(() => frequencyCaps.value.length < 2)
  const canDeleteFrequencyCap = computed(() => frequencyCaps.value.length > 1)
  const advancedManagementAvailable = (cap: FrequencyCap) => isAdvancedManagementAvailable(!isDirectSold, cap)

  const frequencyCapsArray = useFieldArray('frequency.frequencyCaps')

  const updateFrequencyCap = (index: number, value: Partial<FrequencyCap>) => {
    const cap = frequencyCapsArray.fields.value[index]

    if (cap) {
      frequencyCapsArray.update(index, { ...cap.value, ...value })
    }
  }

  const addFrequencyCap = () => {
    frequencyCapsArray.push(buildFrequencyCap())
  }

  const removeFrequencyCap = (index: number) => {
    frequencyCapsArray.remove(index)
  }

  whenever(() => frequencyCaps.value.length === 0 && toValue(isCardEnabled), () => {
    addFrequencyCap()
  })

  return {
    frequencyCaps,
    recencyValue,
    recencyTimeUnit,
    loading,
    error,
    request,

    canAddFrequencyCap,
    canDeleteFrequencyCap,
    advancedManagementAvailable,

    getId,
    updateFrequencyCap,
    removeFrequencyCap,
    addFrequencyCap,
  }
})
